<template>
  <div id="app">
    <v-app id="inspire">
      <v-app id="inspire">
        <v-main>
          <v-container class="fill-height" fluid>
            <v-row align="center" justify="center">
              <v-col cols="6">
                <v-card class="elevation-0 login-formMainDiv">
                  <v-card-title class="text-h4 font-weight-bold my-3">Forgot Password</v-card-title>
                  <!-- <v-toolbar color="primary" dark flat>
                    <v-toolbar-title>Forgot Password</v-toolbar-title>
                    <v-spacer></v-spacer>
                  </v-toolbar> -->
                  <v-card-text class="pb-0">
                    <v-form>
                      <v-text-field label="email" name="email" outlined dense type="text" v-model="form.email"
                        :error="errors.email != null" :error-messages="errors.email"></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="px-4">
                    <v-btn :loading="loading" block color="black" class="white--text" @click="onSubmit"
                      :disabled="loading">Submit</v-btn>
                  </v-card-actions>
                </v-card>
              </v-col>
              <v-col cols="6" class="loginBgImg">
                <!-- <img src="../../assets/Group.svg" width="100%" /> -->
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-app>
    </v-app>
    <v-snackbar v-model="snackbar" :timeout="3000" color="green accent-4" align-baseline="center">
      {{ message }}111

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      loading: false,
      form: {
        email: "",
        password: "",
      },
      errors: {},
      message: "",
      snackbar: false,
    };
  },
  methods: {
    onSubmit(event) {
      let _self = this;
      event.preventDefault();

      _self.loading = true;

      this.$axios.post("admin/forgotPassword", _self.form).then((res) => {
        let responsedata = res.data;
        _self.loading = false
        // _self.snackbar = true

        if (responsedata.success) {
          _self.snackbar = true
          _self.message = responsedata.message
          setTimeout(() => {
            _self.$router.push('/login')
          }, 3000);
        }
        else {
          responsedata.errors ? _self.errors.email = responsedata.errors[0].message : _self.errors.email = responsedata.message
        }

      });
    },
  },
};
</script>

<style>
.loginBgImg {
  background-image: url('../../assets/login-v1.svg');
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.login-formMainDiv {
  width: min(100%, 400px);
  margin-inline: auto;
}
</style>
